import {createAction, props} from '@ngrx/store';
import {CdNotification} from '../notification/notification.reducer';
import {AutoSaveError, PendingRequest} from './auto-save.reducer';

export const autoSaveRequestStarted = createAction(
  '[auto save] request started',
  props<{
    payload: PendingRequest;
  }>()
);
export const autoSaveRequestFinished = createAction(
  '[auto save] finished request',
  props<{
    payload: PendingRequest;
  }>()
);
export const autoSaveRequestFailed = createAction(
  '[auto save] failed',
  props<{
    request: PendingRequest;
    error?: AutoSaveError | CdNotification;
  }>()
);
export type AutoSaveActions = ReturnType<typeof autoSaveRequestStarted> | ReturnType<typeof autoSaveRequestFinished> | ReturnType<typeof autoSaveRequestFailed>;
